import React, { useContext, useState } from "react";
import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	type FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "../../Api/gen";
import { IframeType } from "./Hooks";

type ContextValue = {
	gameConfig: FxIdWebFeaturesGamesChooseGameResponsePublicGameData;
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
	adapter: IframeType;
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
	setAdapter: (adapter: IframeType) => void;
	setPlatform: (platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform) => void;

	gameId?: string;
};

const GameContext = React.createContext<ContextValue | undefined>(undefined);

// export const GameContextProvider = GameContext.Provider;
export const GameContextProvider = ({
	children,
	value
}: {
	children: React.ReactNode;
	value: Omit<ContextValue, "setAdapter" | "setPlatform">;
}) => {
	const [adapter, setAdapter] = useState<IframeType>(value.adapter);
	const [platform, setPlatform] = useState<FxIdDomainStoreEnumsSupportedWebPublishingPlatform>(value.platform);

	return (
		<GameContext.Provider value={{ ...value, adapter, platform, setAdapter, setPlatform }}>
			{children}
		</GameContext.Provider>
	);
};

export const useGameContext = (): ContextValue => {
	const contextValue = useContext(GameContext);
	if (!contextValue) {
		console.warn(
			"%cОтсутствует Provider для GameContext!",
			"font-weight: bold;",
			"Добавьте его выше в дереве компонентов. Возможно, вы используете хук в неподходящем месте"
		);
		throw new Error("No context");
	}
	return contextValue;
};
