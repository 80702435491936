import {
	EFxIdSdkGender,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterGetFriendsRequest,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkGetFriendsResult,
	IFxIdSdkGetFriendsResultFriend,
	IFxIdSdkWordsFilterRequest,
	IFxIdSdkWordsFilterResult
} from "./FxIdSdkBaseAdapter";
import { deferred } from "./FxIdSdkUtils";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayGamePublicConfigResponse,
	FxIdWebFeaturesStoreCreatePaymentEmbeddingType
} from "../Api/gen";

type IWizQGetUsersInfoResult = Record<
	string,
	{
		uid: string;
		displayName?: string;
		gender?: 0 | 1 | 2;
		picUrl?: string;
	}
>;

interface IWizQValidateUserInputResult {
	result: boolean;
}

interface IWizQCommand {
	command: string;
	num: number;
	data?: unknown;
}

export class FxIdSdkAdapterForWizQ extends FxIdSdkBaseAdapter {
	private buyProductResolve?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;
	private buyProductReject?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;

	private _userId?: string;
	private _wizQRequestNum = 0;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayGamePublicConfigResponse
	) {
		super(exportedSdk);
	}

	RegisterShareHandlers(): Promise<void> {
		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve({
			ui: {
				disabled: true
			}
		});
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ,
			EmbeddingType: FxIdWebFeaturesStoreCreatePaymentEmbeddingType.Embed
		});

		console.log("Received result from server: %o", createPaymentResult);

		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();

		this.buyProductResolve = (data: any) => {
			resolve({ ...data, transactionId: createPaymentResult.TransactionId });
		};
		this.buyProductReject = reject;

		wqapi.requestPayment(
			createPaymentResult.WizQ!.SecuredOrderId!.toString(),
			1,
			createPaymentResult.OrderPrice,
			createPaymentResult.OrderProduct.LocalizedProductName,
			createPaymentResult.OrderProduct.ImageUrl,
			wizqConfig.handlePaymentURL,
			(returnCode, returnMessage) => {
				console.log("wqapi.requestPayment: returnCode: %o, returnMessage: %o", returnCode, returnMessage);

				if (returnCode === WizQAPI.ReturnCode.SUCCESS.code) {
					this.buyProductResolve?.call(this, {});
				} else {
					this.buyProductReject?.call(this, {});
				}
			}
		);

		return promise;
	}

	async GetUserIdToken(): Promise<string> {
		const getUserIdCommandName = "getUserId";
		const userCommandReply = await this.MakeProxyRequest({
			command: getUserIdCommandName,
			num: this._wizQRequestNum++
		});
		// Запросы два раза заэнкожены
		const reply = JSON.parse(JSON.parse(userCommandReply));
		return reply.Token;
	}

	async MakeProxyRequest(command: IWizQCommand) {
		// Т.к. wizq шлет по сути данные формы, то отдаем ему джейсон в application/x-www-form-urlencoded
		const data = encodeURI(`json=${JSON.stringify(command)}`);
		const url = (import.meta.env.BASE_URL ?? "") + `/api/v1/wizq/proxyRequestHandler/${this.game}`;

		console.log("Making proxy request with command %o. Encoded data: %s. Url: %s", command, data, url);

		return await new Promise<string>((resolve, reject) => {
			wqapi.makeRequest(url, "POST", data, (returnCode, returnMessage, response) => {
				console.log(
					"Received proxy response to command %o with code %s, message %s, response %s",
					command,
					returnCode,
					returnMessage,
					response
				);
				if (returnCode === WizQAPI.ReturnCode.SUCCESS.code) {
					//success
					resolve(response as string);
				} else {
					reject();
				}
			});
		});
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const userId = await this.WizQGetUserId();

		const userInfo = (await this.WizQGetUsersInfo([userId]))[userId];
		const gender = this.MapWizQGender(userInfo.gender);

		return Promise.resolve({
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki,
			userId: userId,
			displayName: userInfo.displayName,
			photo: userInfo.picUrl ? { url: userInfo.picUrl } : undefined,
			gender,
			capabilities: []
		});
	}

	async Initialize(): Promise<void> {
		console.log("Initializing WizQ adapter");
		// Load the SDK's source Asynchronously

		await new Promise<void>((resolve, reject) => {
			const wizqBootStrap = new WizQBootStrap();
			wizqBootStrap.init(wizqConfig);
			wizqBootStrap.registerOnLoadHandler(() => {
				// NOTE! Это сейчас происходит в index.html
				// wqapi.resetHeight();

				wqapi.getSelfID((code, message, userId) => {
					if (code !== WizQAPI.ReturnCode.SUCCESS.code || !userId) {
						return reject({ code, message });
					}
					this._userId = userId;
					return resolve();
				});
			});

			console.log("Script added");
		});

		console.log("Initializing api");
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	async StatInitialize(_request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(_request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	override async GetFriends(_request: IFxIdSdkAdapterGetFriendsRequest): Promise<IFxIdSdkGetFriendsResult> {
		const friendsData = await this.WizQGetFriendsInfo();

		const result: IFxIdSdkGetFriendsResult = { friends: [] };

		for (const friendData of Object.values(friendsData)) {
			const gender = this.MapWizQGender(friendData.gender);
			const friend: IFxIdSdkGetFriendsResultFriend = {
				uid: friendData.uid,
				displayName: friendData.displayName,
				gender,
				photo: friendData.picUrl ? { url: friendData.picUrl } : undefined
			};

			result.friends.push(friend);
		}

		return result;
	}

	override async WordsFilter(request: IFxIdSdkWordsFilterRequest): Promise<IFxIdSdkWordsFilterResult> {
		const wizQResult = await this.WizQValidateUserInput(request.text);
		return { success: wizQResult.result };
	}

	private WizQGetUserId() {
		return new Promise<string>((resolve, reject) => {
			if (this._userId) return resolve(this._userId);

			wqapi.getSelfID((code, message, userId) => {
				if (code !== WizQAPI.ReturnCode.SUCCESS.code || !userId) return reject({ code, message });

				this._userId = userId;
				return resolve(userId);
			});
		});
	}

	private WizQGetFriendsInfo(hasApp = false) {
		return new Promise<IWizQGetUsersInfoResult>((resolve, reject) => {
			wqapi.getFriendsID(hasApp, (code, message, friendIds) => {
				if (code !== WizQAPI.ReturnCode.SUCCESS.code || !friendIds) return reject({ code, message });

				this.WizQGetUsersInfo(friendIds)
					.then((friendsData) => {
						resolve(friendsData);
					})
					.catch((reason) => {
						reject(reason);
					});
			});
		});
	}

	private WizQValidateUserInput(input: string) {
		return new Promise<IWizQValidateUserInputResult>((resolve, reject) => {
			wqapi.validateUserInput(input, (code, message, result) => {
				if (code !== WizQAPI.ReturnCode.SUCCESS.code) return reject({ code, message });

				resolve({ result: result as boolean });
			});
		});
	}

	private WizQGetUsersInfo(userIds: string[]): Promise<IWizQGetUsersInfoResult> {
		return new Promise<IWizQGetUsersInfoResult>((resolve, reject) => {
			wqapi.getUsersProfile(
				userIds,
				[
					WizQAPI.User.Fields.FIELD_ID,
					WizQAPI.User.Fields.FIELD_DISPLAY_NAME,
					WizQAPI.User.Fields.FIELD_GENDER,
					WizQAPI.User.Fields.FIELD_PROFILE_IMAGE_URL
				],
				(code, message, data) => {
					const res: IWizQGetUsersInfoResult = {};
					if (code !== WizQAPI.ReturnCode.SUCCESS.code || !data) return reject({ code, message });
					for (const userInfo of Object.values(data)) {
						res[userInfo[WizQAPI.User.Fields.FIELD_ID]] = {
							uid: userInfo[WizQAPI.User.Fields.FIELD_ID],
							displayName: userInfo[WizQAPI.User.Fields.FIELD_DISPLAY_NAME],
							gender: userInfo[WizQAPI.User.Fields.FIELD_GENDER],
							picUrl: userInfo[WizQAPI.User.Fields.FIELD_PROFILE_IMAGE_URL]
						};
					}
					resolve(res);
				}
			);
		});
	}

	private MapWizQGender(gender?: 0 | 1 | 2) {
		switch (gender) {
			case 1:
				return EFxIdSdkGender.Male;
			case 2:
				return EFxIdSdkGender.Female;
			default:
				return EFxIdSdkGender.Unspecified;
		}
	}
}
