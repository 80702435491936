import * as jsonpath from "jsonpath";
import vkBridge from "@vkontakte/vk-bridge";
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents } from "../../Api/gen";

type Matcher = { [key: string]: unknown };

export interface VkComMapper {
	pixel_code: string;
	conversion_event: string;
	conversion_value: string;
}

export interface GTagMapper {
	[key: string]: string;
}

export interface ConfigSection {
	sendTo: Array<"vkcom" | "gtag">;
	mapper: { vkcom?: VkComMapper; gtag?: GTagMapper };
	matcher: Matcher;
}

type Event = unknown;

export class FxIdEventProcessor {
	private readonly config: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents;

	constructor(config: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents) {
		this.config = config;
	}

	private matchesConfig(event: Event, matcher: Matcher): boolean {
		for (const key in matcher) {
			if (Object.prototype.hasOwnProperty.call(matcher, key)) {
				if (key.startsWith("$")) {
					const jsonPath = key;
					const expectedValue = matcher[key];
					const actualValue = jsonpath.query(event, jsonPath);

					if (actualValue.length === 0 || actualValue[0] !== expectedValue) {
						return false;
					}
				} else {
					const expectedValue = matcher[key];
					const actualValue = (event as never)[key];

					if (actualValue !== expectedValue) {
						return false;
					}
				}
			}
		}
		return true;
	}

	public findMatchingConfigSection(event: Event): string | null {
		for (const sectionKey in this.config.Sections) {
			if (Object.prototype.hasOwnProperty.call(this.config.Sections, sectionKey)) {
				const section = this.config.Sections[sectionKey];
				if (this.matchesConfig(event, section.Matcher)) {
					return sectionKey;
				}
			}
		}
		return null;
	}

	public async processEvent(event: Event) {
		const matchedSectionKey = this.findMatchingConfigSection(event);
		if (!matchedSectionKey) {
			if (import.meta.env.DEV) {
				console.warn("No matching config section found for the event.");
			}
			return;
		}

		const matchedSection = this.config.Sections[matchedSectionKey];

		await Promise.all(
			matchedSection.SendTo.map((destination) => {
				if (destination === "vkcom") {
					return this.processVkCom(event, matchedSection.Mapper.vkcom);
				} else if (destination === "gtag") {
					return this.processGTag(event, matchedSection.Mapper.gtag);
				}
			})
		);
	}

	private async processVkCom(event: Event, mapper?: VkComMapper) {
		if (!mapper) {
			return;
		}

		const pixelCode = mapper.pixel_code;
		const conversionEvent = mapper.conversion_event;
		const conversionValuePath = mapper.conversion_value;
		const conversionValue = parseFloat(jsonpath.query(event, conversionValuePath)[0]);
		const vkConversionEvent = {
			pixel_code: pixelCode,
			conversion_event: conversionEvent,
			conversion_value: conversionValue
		};
		console.log("Sending to vkcom: %o", vkConversionEvent);
		await vkBridge.send("VKWebAppConversionHit", vkConversionEvent);
	}

	private processGTag(event: Event, mapper?: GTagMapper): Promise<void> {
		if (window.dataLayer == null) {
			return Promise.resolve();
		}

		if (mapper) {
			const gtagEvent: { [key: string]: unknown } = {};
			for (const key in mapper) {
				if (Object.prototype.hasOwnProperty.call(mapper, key)) {
					const valuePath = mapper[key];
					gtagEvent[key] = jsonpath.query(event, valuePath)[0];
				}
			}
			console.log("Sending to gtag: %o", gtagEvent);

			// event = gtagEvent;
			window.dataLayer.push(gtagEvent);
		} else {
			console.log("Sending to gtag: %o", event);
			window.dataLayer.push(event);
		}

		return Promise.resolve();
	}
}
