import { GOOGLE_TAG_MANAGER_IDS } from "../../Config";

export function getGoogleTagManagerTagData() {
	function inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function onVk() {
		try {
			return (
				window.self.location.href.indexOf("vk.com") !== -1 ||
				(window.top != null && window.top.location.href.indexOf("vk.com") !== -1)
			);
		} catch (e) {
			return false;
		}
	}

	// В айфрейме гугель тег не грузим
	if (inIframe()) {
		console.log("Google tag disabled in iframes");
		return;
	}

	if (onVk()) {
		console.log("Google tag disabled on vk");
		return;
	}

	const trackerId = GOOGLE_TAG_MANAGER_IDS.get(window.location.host);
	if (!trackerId) {
		console.warn(`[GTM] No tracker ID present for host ${window.location.host}`);

		console.warn("[GTM] Skipping gtag initialization");
		return;
	}

	return trackerId;
	// gtag("config", trackerId);
}

export * as sdk from "./sdkEvents";
export * as site from "./siteEvents";
