import { useLocalStorage } from "@mantine/hooks";
import { deferred } from "../SDK/FxIdSdkUtils";
// import { initVkApi } from "../Stores";
import { signOutFromFirebase } from "./Firebase";
import OpenApiClient from "./OpenApiClient";

export const AuthTokenLocalStorageKey = "AuthToken";

let currentAuthToken: string | null = null;

export function setAuthToken(token: string): void {
	currentAuthToken = token;
	try {
		localStorage.setItem(AuthTokenLocalStorageKey, token);
	} catch (err) {
		console.error(err);
	}
}

export function getAuthToken(): string | null {
	try {
		return localStorage.getItem(AuthTokenLocalStorageKey);
	} catch (err) {
		console.error(err);
	}
	return currentAuthToken;
}

export function authTokenExists(): boolean {
	try {
		return localStorage.getItem(AuthTokenLocalStorageKey) != null;
	} catch (err) {
		console.error(err);
	}
	return currentAuthToken != null;
}

/** Хук для получениея auth token. Подписывается на изменения в local storage */
export function useAuthToken() {
	try {
		const [token] = useLocalStorage({ key: AuthTokenLocalStorageKey });
		return token;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function logout(): Promise<void> {
	try {
		localStorage.removeItem(AuthTokenLocalStorageKey);
	} catch (err) {
		console.error(err);
	}
	currentAuthToken = null;
	// await signOutFromFirebase();
	await OpenApiClient.Auth.fxIdWebFeaturesAuthLogoutEndpoint();

	// const vkApiRef = "_apiId" in window.VK && (window.VK as any)._apiId != null ? window.VK : initVkApi();
	// if (vkApiRef) {
	// 	return new Promise((resolve) => {
	// 		vkApiRef.Auth.getLoginStatus((data) => {
	// 			if (data.status === "connected") vkApiRef.Auth.logout(() => console.log("[VK] Logout callback fired"));
	// 			resolve();
	// 		});
	// 	});
	// }
}
