import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, GoogleAuthProvider, signOut, User, EmailAuthProvider, Auth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Props } from "react-firebaseui";

import { setAuthToken } from "./Auth";
import { FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest } from "./gen";
import OpenApiClient from "./OpenApiClient";
import i18next from "i18next";

const appleProvider = new OAuthProvider("apple.com");
appleProvider.setCustomParameters({
	locale: i18next.language
});

// Initialize Firebase
let app: FirebaseApp | undefined;
let auth: Auth | undefined;

const firebaseInit = (firebaseConfig: FirebaseOptions) => {
	if (app && auth) return { app, auth };

	app = initializeApp(firebaseConfig);
	auth = getAuth(app);
	return { app, auth };
};

const loginWithFirebaseUser = async (game: string, user: User, marketingEnabled: boolean | undefined) => {
	const data: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest = {
		GameSystemName: game,
		Token: await user.getIdToken(),
		MarketingEnabled: marketingEnabled
	};

	try {
		const { Token } = await OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromFirebaseEndpoint(data);
		setAuthToken(Token);
	} catch (err) {
		console.error(err);
		throw err;
	}
};

const signOutFromFirebase = async () => {
	if (!auth) {
		throw new Error("Firebase app is not initialized! Call firebaseInit() first");
	}
	return signOut(auth);
};

const useFirebaseUser = () => {
	if (!auth) {
		throw new Error("Firebase app is not initialized! Call firebaseInit() first");
	}
	const [firebaseUser, setFirebaseUser] = useState<User | null>();

	// Listen to the Firebase Auth state and set the local state.
	useEffect(() => {
		if (!auth) return;
		const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
			setFirebaseUser(user);
		});
		return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
	}, []);
	return firebaseUser;
};

export { auth, app as firebaseApp, loginWithFirebaseUser, useFirebaseUser, signOutFromFirebase, firebaseInit };
