import {
	EFxIdSdkAdapterInfoSocialCapability,
	FxIdSdkAdapterSocialSettingDefault,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterAuthenticateUserRequest,
	IFxIdSdkAdapterAuthenticateUserResponse,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkInviteFriendsResult
} from "./FxIdSdkBaseAdapter";
import OpenApiClient, { openApiConfig } from "../Api/OpenApiClient";
import {
	FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayGamePublicConfigResponse,
	FxIdWebFeaturesStoreCreatePaymentEmbeddingType
} from "../Api/gen";
import { getQueriedProfileData } from "../Api/Queries";
import { openLinkShareModal } from "../Components/Modals";
import { FxIdSdkAdapterForFxId } from "./FxIdSdkAdapterForFxId";
import { CrazyGamesSDKUserUser } from "../crazy_games_sdk";
import {
	FxIdStats,
	FxIdStatsTutorialCompleted,
	FxIdStatsTutorialSkip,
	FxIdStatsTutorialStart,
	FxIdStatsTutorialStep
} from "./FxIdStats";
import { delayPromise } from "./FxIdSdkUtils";
import vkBridge, { RequestPropsMap } from "@vkontakte/vk-bridge";

export class FxIdSdkAdapterForCrazyGames extends FxIdSdkAdapterForFxId {
	private currentCGUser?: CrazyGamesSDKUserUser | null;
	private fxIdStats: FxIdStats;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayGamePublicConfigResponse
	) {
		super(exportedSdk, game, config);
		this.fxIdStats = new FxIdStats();
	}

	override async RegisterShareHandlers(): Promise<void> {
		// const { enableLinkCopy, enableShareOk, enableShareTelegram, enableShareVk } = sharingHandlersStore.getState();
		// const { enableWebPlayLogin } = appStore.getState();
		// const data = { url: window.location, title: i18next.t(`seo.${this.game}.title`) };
		// enableShareTelegram(() => telegram(data));
		// enableLinkCopy();
		//
		// /*
		//  * TODO: Или переместить в более логичное место или переименовать метод, чтобы можно было подобные подвязыки
		//  * включать туn
		//  */
		// enableWebPlayLogin();
		//
		// const profileData = await getQueriedProfileData();
		//
		// const user: any | null = await window.CrazyGames.SDK.user.getUser();
		// console.log("Get user result", user);
		//
		// const userDisplayName = user?.user ?? profileData.Name ?? profileData.Email ?? `#${profileData.AccountId}`;
		//
		// userStore.getState().updateUserId(String(profileData.AccountId));
		// userStore.getState().updateDisplayName(userDisplayName);
		//
		// return Promise.resolve();
		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve({ ...FxIdSdkAdapterSocialSettingDefault, ui: { disabled: true } });
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const isAvailable = window.CrazyGames.SDK.user.isUserAccountAvailable;

		if (!isAvailable) {
			console.error("User accounts not available");
			return { error: "User accounts not available" };
		}
		let user = await window.CrazyGames.SDK.user.getUser();

		if (user == null) {
			console.warn("User not registered - asking to register");

			try {
				const user = await window.CrazyGames.SDK.user.showAuthPrompt();
				console.log("Auth prompt result", user);
				// window.location.reload();
			} catch (e: any) {
				console.log("Error:", e);
				return { error: e?.message ?? "Error" };
			}
		}

		user = await window.CrazyGames.SDK.user.getUser();

		if (user == null) {
			console.log("User still not registered");
			return { error: "User still not registered" };
		}

		const profileData = await getQueriedProfileData();

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames,
			EmbeddingType: FxIdWebFeaturesStoreCreatePaymentEmbeddingType.Embed
		});

		console.log("Received result from server: %o", createPaymentResult);
		return this.StartPayment(createPaymentResult);
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const id = await this.CrazyGamesUserId();
		const user = await window.CrazyGames.SDK.user.getUser();

		const result: IFxIdSdkAdapterInfoSocial = {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames,
			userId: id,
			displayName: user?.username,
			capabilities: [
				EFxIdSdkAdapterInfoSocialCapability.FriendsInvite,
				EFxIdSdkAdapterInfoSocialCapability.GamesOfChanceDisabled
			]
		};

		if (user?.profilePictureUrl != null) {
			result.photo = {
				url: user?.profilePictureUrl
			};
		}

		return result;
	}

	private async CrazyGamesUserId(): Promise<string> {
		const profileData = await getQueriedProfileData();
		const guestId = profileData.SocialIdentifiers.find(
			(el) => el.Type === FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType.CrazyGamesGuest
		);
		const mainId = profileData.SocialIdentifiers.find(
			(el) => el.Type === FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType.CrazyGames
		);

		if (mainId != null) {
			return mainId.Id;
		} else if (guestId != null) {
			return guestId.Id;
		}
		throw new Error("No registration to crazygames!");
	}

	async Initialize(): Promise<void> {
		await super.Initialize();

		console.log("Initializing CrazyGames");

		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = "https://sdk.crazygames.com/crazygames-sdk-v3.js";
			s.addEventListener(
				"load",
				function (_e) {
					console.log("Script loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			console.log("Script added");
		});

		await window.CrazyGames.SDK.init();

		const environment: string = await window.CrazyGames.SDK.environment;
		console.log("CrazyGames environment: %o", environment); // 'local', 'crazygames' or 'disabled'

		// CrazyGames попросили что бы это событие вызывала игра когда пользователь уже прямо играет
		// try {
		// 	// window.CrazyGames.SDK.game.gameplayStart(); // result is always undefined for gameplayStart/Stop
		// 	console.log("Gameplay start triggered");
		// } catch (e) {
		// 	console.log("Gameplay start error", e);
		// }

		const isAvailable = window.CrazyGames.SDK.user.isUserAccountAvailable;
		console.log("User account available: %o", isAvailable);

		const systemInfo = window.CrazyGames.SDK.user.systemInfo;
		console.log("Get system info result: %o", systemInfo);

		this.currentCGUser = await window.CrazyGames.SDK.user.getUser();

		console.log("Adding auth listener");
		// add listener
		// https://docs.crazygames.com/sdk/user/html5-v2/#auth-listeners
		window.CrazyGames.SDK.user.addAuthListener(this.CrazyGamesAuthListener.bind(this));

		console.log("Initialized CrazyGames");
	}

	/**
	 * Фидбек от крейзи. Проще говоря когда пользователя не стало надо логаутится полностью и создавать нового гостя
	 *
	 * `When we play as a guest -> make progress -> then log in/register to a fresh account (e.g., account A),
	 * the progress will be carried over to that account, which is good. However, when we logged out immediately,
	 * I found an issue that the game was still logged in with that account A. This issue only happens when the game carries over the guest's progress.
	 * Could you please double-check, and ensure that when a getUser null is detected the game will always start as a guest? Thank you!
	 * @param user
	 * @constructor
	 */
	async CrazyGamesAuthListener(user: CrazyGamesSDKUserUser | null) {
		console.log("CrazyGames auth listener called with user %o. Current user: %o", user, this.currentCGUser);

		// NOTE: Это конечно же ужасный говнохак. Проблема в том что крейзи шлет авторизацию иногда несколько раз подряд
		while (this.exportedSdk.IsAuthenticationApiInProgress()) {
			console.log("Authentication already in progress. Waiting for it to complete");
			await delayPromise(500);
		}

		const newCrazyGamesAccount = await window.CrazyGames.SDK.user.getUser();

		console.log("Receive user in callback: %o", newCrazyGamesAccount);

		// Был пользователь, а теперь не стало - перезагрузка. После перезагрузки найдется или будет создан гость
		if (this.currentCGUser != null && newCrazyGamesAccount == null) {
			console.log("Had crazygames user account %o, but now user account is not available. Reloading");
			// Сначала логаутимя
			await OpenApiClient.Auth.fxIdWebFeaturesAuthLogoutEndpoint();
			window.location.reload();
			return;
		}

		const token = newCrazyGamesAccount != null ? await this.GetUserToken() : null;

		// Был пользователь, а теперь почему-то токен нулл - не стало. После перезагрузки будет создан гость
		if (this.currentCGUser != null && token == null) {
			console.log("Had user account %o, but now user token is not available. Reloading");
			await OpenApiClient.Auth.fxIdWebFeaturesAuthLogoutEndpoint();
			window.location.reload();
			return;
		}

		if (this.currentCGUser == null && token == null) {
			console.log("Nothing changed. We had no user and have not received token (null). Skipping");
			return;
		}

		console.log("New user token %s. Trying to authenticate", token);

		// Еще разок проверим - не должно быть, но крейзики очень крейзи, а мы "когда-нибудь это перепишем"
		while (this.exportedSdk.IsAuthenticationApiInProgress()) {
			console.log("Authentication already in progress. Waiting for it to complete");
			await delayPromise(500);
		}

		const authenticationResult = await this.exportedSdk.AuthenticateApi({
			Game: this.game,
			Social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
				FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames
			],
			CrazyGames: {
				Token: token ?? "",
				IsGuest: token == null
			}
		});

		console.log("Authentication result: %o", authenticationResult);

		if (authenticationResult.changeEvent != null) {
			console.log("Account changed. Reloading.");
			await OpenApiClient.Auth.fxIdWebFeaturesAuthLogoutEndpoint();
			window.location.reload();
			return;
		}
	}

	async GetUserToken(): Promise<string | null> {
		try {
			const token = await window.CrazyGames.SDK.user.getUserToken();
			console.log("Get token result", token);
			return token;
		} catch (e) {
			console.log("Error:", e);
		}

		return null;
	}

	override AdsIsVideoReady(): Promise<boolean> {
		window.FxIdSdk!.DispatchAdsVideoAvailable();
		return Promise.resolve(true);
	}

	override AdsShowVideo(): Promise<void> {
		console.log("[VIDEO ADS] Ad requested");
		const callbacks = {
			adFinished: () => {
				console.log("End rewarded ad (callback)");
				window.FxIdSdk!.DispatchAdsFinished();
			},
			adError: (error: unknown, errorData: unknown) => {
				console.log("Error rewarded ad (callback) or not filled. %o. %o", error, errorData);
				window.FxIdSdk!.DispatchAdsFailed();
			},
			adStarted: () => {
				console.log("Start rewarded ad (callback)");
			}
		};

		window.CrazyGames.SDK.ad.requestAd("rewarded", callbacks);

		return Promise.resolve();
	}

	override async InviteFriends(): Promise<IFxIdSdkInviteFriendsResult> {
		const profileData = await getQueriedProfileData();

		const link = window.CrazyGames.SDK.game.inviteLink({ roomId: 12345, param2: "value", param3: "value" });
		console.log("Invite link", link);

		// HACK: У нас нет способа забрать из OpenApiClient точный путь до эндпоинта с редиректом, поэтому собираем его
		// руками и надеемся, что все не сломается
		const redirectUrl = new URL(`${openApiConfig.basePath}/api/v1/go`);
		redirectUrl.searchParams.append("fxref", String(profileData.AccountId));
		redirectUrl.searchParams.append("url", link);
		openLinkShareModal({ link: redirectUrl.toString() });
		return { friends: [] };
	}

	async AuthenticateUser(
		request: IFxIdSdkAdapterAuthenticateUserRequest
	): Promise<IFxIdSdkAdapterAuthenticateUserResponse> {
		const isAvailable = window.CrazyGames.SDK.user.isUserAccountAvailable;

		if (!isAvailable) {
			throw new Error("User accounts not available");
		}

		const user = await window.CrazyGames.SDK.user.getUser();

		if (user == null) {
			console.warn("User not registered - asking to register");

			try {
				const user = await window.CrazyGames.SDK.user.showAuthPrompt();
				console.log("Auth prompt result", user);
				// window.location.reload();
				return {};
			} catch (e) {
				console.log("Error:", e);
				throw e;
			}
		} else {
			return {};
		}
	}

	async StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		const statEvent = this.fxIdStats.ProcessStat(request);
		return Promise.resolve(undefined);
	}

	public async SendCrazyGamesSdk(methodName: string, params?: any) {
		// Split the methodName string to navigate through the SDK object
		const parts = methodName.split(".");
		let currentPart = window.CrazyGames.SDK as any;
		let parent = null; // Initialize a variable to keep track of the parent object

		try {
			// Navigate through the SDK object based on the parts
			for (const part of parts) {
				parent = currentPart; // Update parent to the current object before moving to the next part
				currentPart = currentPart[part];
				if (currentPart === undefined) {
					throw new Error(`Method ${methodName} not found.`);
				}
			}

			// If the last part is a function, call it with the provided params
			if (typeof currentPart === "function") {
				// Bind `this` to the method. Use the parent object as `this` context for the method call
				const methodWithBoundThis = currentPart.bind(parent);
				// Adjust this part if some methods don't return a promise or have different signatures
				return await methodWithBoundThis(params);
			} else {
				throw new Error(`The provided path ${methodName} does not refer to a function.`);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	}
}
