import * as React from "react";
import { Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { getI18n, Trans, useTranslation } from "react-i18next";
import { getPushServerKey } from "../../Config";
import i18next from "i18next";

/**
 * Открывает модальное окно с уведомлением, что игра хочет получить разрешение на отправку пушей
 * @param params Параметры модалки
 */
export function openPushPermissionModal(params: {
	onPushSubscriptionSuccess: (subscription: PushSubscription) => void;
	onPushSubscriptionFail: () => void;
	serverKey: string;
	gameName: string;
}) {
	const title = i18next.t("push_permission_modal.title");
	const message = i18next.t("push_permission_modal.message", { game: params.gameName });
	const cancelLabel = i18next.t("button_cancel");
	const confirmLabel = i18next.t("button_ok");

	return openConfirmModal({
		modalId: "push-permission",
		size: "sm",
		title: title,
		children: <Text>{message}</Text>,
		labels: { cancel: cancelLabel, confirm: confirmLabel },
		onConfirm: () => {
			subscribeToPushes(params.serverKey)
				.then((subscription) => {
					if (subscription) return params.onPushSubscriptionSuccess(subscription);
					params.onPushSubscriptionFail();
				})

				.catch((e) => console.error("[PUSH] Unhandled error while subscribing to push notifications", e));
		}
	});
}

/**
 * Проверяет возможность и подписывает браузер на пуш-уведомления, если это возможно
 * @param serverKey публичный ключ VAPID для конкретного деплоя конкретной игры
 * @returns Promise, разрешающийся в объект PushSubscription, если все прошло успешно или в undefined
 */
export async function subscribeToPushes(serverKey: string) {
	if (!("serviceWorker" in navigator)) {
		console.warn("[PUSH] Service workers unavailable in browser. Skipping subscription");
		return;
	}
	const registration = await getServiceWorkerRegistration();
	if (!registration) {
		console.warn("[PUSH] No service worker registered. Skipping subscription");
		return;
	}

	try {
		const _ = await requestNotificationPermission();

		console.log("[PUSH] Notification permission granted!");

		const options: PushSubscriptionOptionsInit = {
			userVisibleOnly: true,
			applicationServerKey: serverKey
		};
		return registration.pushManager.subscribe(options);
	} catch (error) {
		console.error("[PUSH] Error while requesting notification permission");
		if (error instanceof Error) {
			console.error(error.message);
		} else {
			console.error(error);
		}

		console.error("[PUSH] Skipping subscription");
	}
}

/**
 * Запрашивает у пользователя разрешение на отображение уведомлений
 *
 * !Выбрасывает ошибку, если разрешение не выдано
 *
 * @returns Успешный результат запроса разрешения на отображение уведомлений
 */
async function requestNotificationPermission() {
	const result = await new Promise<NotificationPermission>(function (resolve, reject) {
		const permissionResult = Notification.requestPermission(function (result) {
			resolve(result);
		});

		if (permissionResult !== undefined) {
			permissionResult.then(resolve, reject);
		}
	});
	if (result !== "granted") {
		throw new Error("[PUSH] Notification permission was not granted");
	}
	return result;
}

// TODO: Убрать, когда пуши будут запрашиваться из игры
// if (import.meta.env.PROD) {
// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 	(window as any).test = {};
// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 	(window as any).test.pushes = function () {
// 		openPushPermissionModal({
// 			// eslint-disable-next-line no-console
// 			onPushSubscriptionFail: () => console.log("[PUSH] Push subscription failed"),
// 			onPushSubscriptionSuccess: (sub) =>
// 				// eslint-disable-next-line no-console
// 				console.log("[PUSH] Push subscription successful!", JSON.stringify(sub)),
// 			serverKey: getPushServerKey(),
// 			gameName: "GAME FROM SDK"
// 		});
// 	};
// }

function getServiceWorkerRegistration() {
	const serviceWorkerScope = window.location.pathname.endsWith("/")
		? window.location.pathname
		: `${window.location.pathname}/`;
	return navigator.serviceWorker.getRegistration(serviceWorkerScope);
}

export async function getPushSubscribtion() {
	const registration = await getServiceWorkerRegistration();
	if (!registration) {
		console.log("[PUSH] No service worker registered");
		return { success: false, subscription: null };
	}
	return { success: true, subscription: await registration.pushManager.getSubscription() };
}
